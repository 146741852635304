<template>
  <v-app>
    <v-main class="login_background">
      <card-login title="Acessar">
        <template v-slot:form>
          <!--  -->
          <!-- Componente obrigatorio - Campos do formulário -->
          <form-login :formData.sync="user" ref="form"></form-login>
          <!-- ............................................... -->
          <!--  -->
        </template>
        <template v-slot:control>
          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <!-- Component obrigatorio - Botão de login  -->
                <v-btn min-width="200px" :loading="loading" rounded x-large color="#133e5d" class="white--text" @click="clickLogin">Entrar</v-btn>
                <!-- ....................................... -->
              </div>
            </v-col>

            <v-col cols="12">
              <!--  -->
              <div class="d-flex justify-center">
                <v-btn small text rounded color="#133e5d" to="/register">Sou aluno(a), quero me cadastrar</v-btn>
              </div>
              <!-- Component obrigatorio - Botão de login  -->
              <!-- ....................................... -->
              <!--  -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn small text color="red" rounded to="/recover">Esqueci a senha</v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </card-login>
    </v-main>
    <dialog-login-error v-if="show_dialog_error" :error_msg="error_msg" @close="handleClose"></dialog-login-error>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    CardLogin: () => import("@/_auth/components/containers/_auth_card_01.vue"),
    FormLogin: () => import("@/_auth/components/forms/Signin.vue"),
    DialogLoginError: () => import('../components/DialogLoginError.vue'),
  },
  data() {
    return {
      user: {
        email: null,
        password: null
      },
      loading: false,
      timeout: 1000,

      error_msg: null,
      show_dialog_error: false,
    };
  },
  methods: {
    handleClose(){
      this.error_msg = null
      this.show_dialog_error = false
    },
    clickLogin() {
      this.loading = true;

      setTimeout(() => {
        if (this.$refs.form.validateForm()) {
          this.submitLogin().then(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }, this.timeout);
    },

    submitLogin() {
      return this.$auth
        .login({
          params: {
            email: this.user.email,
            password: this.user.password
          }
        })

        .then(response => {
          // Fecth usar tem automação dentro da configuração do plugin
          this.$sweetAlerts.toast1("success", "Seja bem vindo!");
        })

        .catch(error => {
          console.log("Erro na tentativa de fazer login..", error);
          this.$sweetAlerts.toast1("error", "Acesso negado!");
          if (error && error.response && error.response.data && error.response.data.errors){
            let err = error.response.data.errors[0]
            if (err.includes('confirmation email was sent')){
              this.error_msg = `Um e-mail de confirmação foi enviado para sua conta em '${this.user.email}'. Você deve seguir as instruções no e-mail antes que sua conta possa ser ativada.`
              this.show_dialog_error = true
            } else {
              this.error_msg = err
              this.show_dialog_error = true
            }

          }
        });
    },
    ...mapActions({})
  }
};
</script>

<style>
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
  /* background: url('../../assets/background_login.jpg') */
}
</style>